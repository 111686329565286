//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchBountyLists } from "@/common/lib/api";
import { researchIllustration } from "@debionetwork/ui-icons";
import { mapState } from "vuex";
export default {
  name: "CustomerDataBounty",
  data: () => ({
    researchIllustration,
    bounties: [],
    headers: [{
      text: "Service Name",
      value: "_source.service_info.name",
      sortable: true
    }, {
      text: "Lab Name",
      value: "_source.lab_info.name",
      width: "300",
      sortable: true
    }, {
      text: "Description",
      value: "_source.service_info.description",
      width: "350",
      sortable: true
    }, {
      text: "Reward",
      value: "reward",
      width: "100",
      sortable: true
    }, {
      text: "Hashcode",
      value: "_id",
      align: "center",
      sortable: false
    }],
    cardBlock: false
  }),
  computed: {
    ...mapState({
      wallet: state => state.substrate.wallet
    })
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 959) this.cardBlock = true;else this.cardBlock = false;
    });
  },
  async created() {
    const data = await fetchBountyLists(this.wallet.address);
    this.bounties = data.map(d => ({
      ...d,
      reward: "1 DBIO"
    }));
  },
  methods: {
    handleCopy(e, hash) {
      e.target.textContent = "Copied";
      this.$nextTick(() => {
        setTimeout(() => {
          e.target.textContent = "Copy";
        }, 1000);
      });
      navigator.clipboard.writeText(hash);
    }
  }
};